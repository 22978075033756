import { Button, ButtonGroup } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const OffButtonContainer = styled.div.attrs((props: { toggled: boolean }) => props)`
  .Polaris-Button {
    ${props =>
      props.toggled &&
      `
      background-color: #6d7175;
      border: 1px solid #575959;
      color: #FFFFFF;
    `}
  }
`;

export const ToggleSwitch: React.FC<{
  onClick: (value: boolean) => any;
  toggled: boolean;
  disabled?: boolean;
}> = ({ onClick, toggled, disabled }) => {
  return (
    <ButtonGroup segmented>
      <Button primary={!disabled && toggled} onClick={() => !disabled && onClick(true)}>
        ON
      </Button>
      <OffButtonContainer toggled={!toggled || disabled}>
        <Button onClick={() => !disabled && onClick(false)}>OFF</Button>
      </OffButtonContainer>
    </ButtonGroup>
  );
};
